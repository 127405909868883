<template>
  <div class="useCar">
    <div class="header-radio-group">
      <el-radio-group v-model="tableType"
                      @change="loadUseCar(1)">
        <el-radio-button :label="1">申请</el-radio-button>
        <el-radio-button :label="2">审核</el-radio-button>
      </el-radio-group>
    </div>
    <div class="header">
      <div>
        <el-button type="success"
                   icon="el-icon-plus"
                   v-if="tableType===1"
                   @click="addUseCar">添加申请</el-button>
      </div>
      <div>
        <el-select placeholder="请选择状态"
                   class="header-query-frame"
                   v-model="queryState">
          <el-option v-for="state in stateList"
                     :key="state.value"
                     :label="state.name"
                     :value="state.value"></el-option>
        </el-select>
        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="header-query-frame"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary"
                   @click="loadUseCar(1)">查 询</el-button>
      </div>
    </div>
    <el-table :data="useCarTable">
      <el-table-column label="用车部门"
                       prop="group_name"
                       align="center"></el-table-column>
      <el-table-column label="有无学生"
                       align="center">
        <template slot-scope="scope">
          {{scope.row.type===1?'有':'无'}}</template>
      </el-table-column>
      <el-table-column label="用车人数"
                       prop="count"
                       align="center"></el-table-column>
      <el-table-column label="开始时间"
                       prop="start_time"
                       align="center"></el-table-column>
      <el-table-column label="结束时间"
                       prop="return_time"
                       align="center"></el-table-column>
      <el-table-column label="审核人"
                       prop="reviewer"
                       align="center"></el-table-column>
      <el-table-column label="取车人"
                       prop="car_opername"
                       align="center"></el-table-column>
      <el-table-column label="取车人电话"
                       prop="mobile"
                       align="center"></el-table-column>
      <el-table-column label="状态"
                       prop="statusStr"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       :width="tableType===1?'220px':'100px'"
                       align="center">
        <template slot-scope="scope">
          <div v-if="tableType===1">
            <el-button size="mini"
                       @click="editUseCar(scope.row,1)"
                       type="primary">详 情</el-button>
            <el-button size="mini"
                       :disabled="scope.row.status!==0"
                       type="warning"
                       @click="editUseCar(scope.row)">编 辑</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="deleteUseCar(scope.$index,scope.row.id)">删 除</el-button>
          </div>
          <div v-else>
            <el-button type="primary"
                       :disabled="scope.row.status!==0"
                       @click="editUseCar(scope.row,2)">审 核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadUseCar"></el-pagination>
    <el-dialog :visible.sync="useFromVisible"
               width="600px"
               :title="useCarForm.id?'用车详情':'申请用车'">
      <el-form :model="useCarForm"
               ref="useCarForm"
               class="use-car-form"
               :rules="useCarFormRules"
               label-width="160px">
        <el-form-item label="用车部门"
                      prop="department">
          <el-select v-model="useCarForm.department"
                     :disabled="buttonType!==0"
                     placeholder="请选择用车部门">
            <el-option v-for="department in departmentList"
                       :key="department.id"
                       :label="department.name"
                       :value="department.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有无学生"
                      prop="studentType">
          <el-radio-group v-model="useCarForm.studentType"
                          :disabled="buttonType!==0">
            <el-radio :label="1">有</el-radio>
            <el-radio :label="2">无</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="用车人数"
                      prop="useCarCount">
          <el-input placeholder="请输入用车人数"
                    :disabled="buttonType!==0"
                    v-model="useCarForm.useCarCount"></el-input>
        </el-form-item>
        <el-form-item label="车辆数"
                      prop="carCount">
          <el-input placeholder="请输入用车数量"
                    :disabled="buttonType!==0"
                    v-model="useCarForm.carCount"></el-input>
        </el-form-item>
        <el-form-item label="开始用车时间"
                      prop="startTime">
          <el-date-picker v-model="useCarForm.startTime"
                          :disabled="buttonType!==0"
                          type="datetime"
                          placeholder="开始用车时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束用车时间"
                      prop="endTime">
          <el-date-picker v-model="useCarForm.endTime"
                          :disabled="buttonType!==0"
                          type="datetime"
                          placeholder="结束用车时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用车人"
                      prop="useCarName">
          <el-select v-model="useCarForm.useCarName"
                     :disabled="buttonType!==0"
                     @change="useCarNameChange">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用车人电话"
                      prop="useCarMobile">
          <el-input v-model="useCarForm.useCarMobile"
                    :disabled="buttonType!==0"
                    placeholder="请输入取车人电话"></el-input>
        </el-form-item>
        <el-form-item label="具体说明"
                      prop="remark">
          <el-input placeholder="请输入说明"
                    :disabled="buttonType!==0"
                    v-model="useCarForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="审批人"
                      prop="approver">
          <el-select v-model="useCarForm.approver"
                     :disabled="buttonType!==0">
            <el-option v-for="approver in approverList"
                       :key="approver.id"
                       :label="approver.mname"
                       :value="approver.managerid"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="buttonType!==0">
          <hr style="border : 1px solid rgb(187,187,187);" />
          <el-form-item label="意见"
                        prop="opinion">
            <el-input placeholder="请输入意见"
                      :disabled="tableType===1"
                      v-model="useCarForm.opinion"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer">
        <div v-if="buttonType===0">
          <!-- 添加修改 -->
          <el-button @click="useFromVisible=false">取 消</el-button>
          <el-button type="primary"
                     @click="useCarConfirm">确 定</el-button>
        </div>
        <!-- 详情 -->
        <el-button @click="useFromVisible=false"
                   type="primary"
                   v-if="buttonType===1">确 定</el-button>
        <div v-if="buttonType===2">
          <!-- 审核 -->
          <el-button @click="useCarApplication(1)">拒 绝</el-button>
          <el-button type="primary"
                     @click="useCarApplication(2)">通 过</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "useCar",
  data() {
    return {
      tableType: 1,
      roleType: false,
      page: { currentPage: 1, pageCount: 0 },
      useCarTable: [],
      useFromVisible: false,
      useCarForm: {
        id: -1,
        department: "",
        studentType: 1,
        useCarCount: "", //用车人数
        carCount: "", //车数量
        startTime: "",
        endTime: "",
        useCarName: "", //取车人
        useCarMobile: "",
        remark: "",
        approver: "", //审批人
        opinion: "",
      },
      useCarFormRules: {
        department: [{ required: true, message: "请选择部门" }],
        studentType: [{ required: true, message: "请选择有无学生" }],
        useCarCount: [{ required: true, message: "请输入用车人数" }],
        carCount: [{ required: true, message: "请输入用车数量" }],
        startTime: [{ required: true, message: "请选择开始时间" }],
        endTime: [{ required: true, message: "请选择结束时间" }],
        useCarName: [{ required: true, message: "请选择取车人姓名" }],
        useCarMobile: [{ required: true, message: "请输入取车人电话" }],
        approver: [{ required: true, message: "请选择审批人" }],
      },
      approverList: [],
      departmentList: [],
      teacherList: [], //取车人列表
      queryTime: "",
      queryState: "",
      stateList: [
        { name: "撤回", value: 3 },
        { name: "通过", value: 2 },
        { name: "拒绝", value: 1 },
        { name: "审批中", value: 0 },
      ],
      buttonType: 0, //1详情，0编辑，2审核
    };
  },
  created() {
    this.loadUseCar(1);
    this.loadApprover();
    this.loadAllTeacher();
    this.loadDepartment();
  },
  methods: {
    loadUseCar(page) {
      console.log(this.queryState);
      let data = {
        indexNo: page,
        type: this.tableType,
        status: this.queryState,
      };
      if (this.queryTime) {
        data.start_time = this.queryTime[0];
        data.return_time = this.queryTime[1];
      }
      this.$post("/base/queryCarRecord.do", data)
        .then((res) => {
          this.useCarTable = res.data.rows;
          this.page.currentPage = res.data.indexNo;
          this.page.pageCount = res.data.pageCount;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadAllTeacher() {
      //查询身份是否校内，1是校内
      this.$post("/media/queryAllManager.do", { is_no: 1 })
        .then((res) => {
          this.teacherList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadDepartment() {
      this.$post("/media/queryGroupListByTea.do", {})
        .then((res) => {
          this.departmentList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadApprover() {
      this.$post("/base/queryApprover.do", { type: 3 }) //3是用车审批人
        .then((res) => {
          this.approverList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    addUseCar() {
      this.useFromVisible = true;
      this.$nextTick(() => {
        this.$refs["useCarForm"].resetFields();
        this.useCarForm.id = "";
        this.useCarForm.approver = this.approverList[0].managerid;
        this.useCarForm.department = this.departmentList[0].id;
      });
    },
    useCarNameChange($event) {
      let len = this.teacherList.length;
      for (let i = 0; i < len; i++) {
        if (this.teacherList[i].id === $event) {
          this.useCarForm.useCarMobile = this.teacherList[i].mobile;
        }
      }
    },
    useCarConfirm() {
      this.$refs["useCarForm"].validate((valid) => {
        if (valid) {
          let data = {
            car_operid: this.useCarForm.useCarName,
            group_id: this.useCarForm.department,
            mobile: this.useCarForm.useCarMobile,
            count: this.useCarForm.useCarCount,
            type: this.useCarForm.studentType,
            reason: this.useCarForm.remark,
            start_time: this.getTime(this.useCarForm.startTime),
            return_time: this.getTime(this.useCarForm.endTime),
            car_count: this.useCarForm.carCount,
            reviewerid: this.useCarForm.approver,
          };
          let apiUrl = "/base/insertCarRecord.do";
          this.$confirm("是否确认此操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: res.message,
                  });
                  this.loadUseCar(1);
                  this.useFromVisible = false;
                })
                .catch((err) => {
                  this.useFromVisible = false;
                  this.$message({
                    type: "warning",
                    message: err.message,
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        } else {
          return false;
        }
      });
    },
    // 时间转换
    getTime(time) {
      let d = new Date(time);
      let year = d.getFullYear();
      let month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return `${year}-${month}-${date} ${hour}:${minutes}`;
    },
    deleteUseCar(index, id) {
      this.$confirm("是否确认删除该申请", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteCarRecord.do", { id })
            .then((res) => {
              this.useCarTable.splice(index, 1);
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    editUseCar(row, type = 0) {
      //type===1详情，0编辑，2审核
      this.buttonType = type;
      this.useFromVisible = true;
      this.$nextTick(() => {
        this.useCarForm.id = row.id;
        this.useCarForm.department = row.group_id;
        this.useCarForm.studentType = row.type;
        this.useCarForm.useCarCount = row.count;
        this.useCarForm.carCount = row.car_count;
        this.useCarForm.startTime = row.start_time;
        this.useCarForm.endTime = row.return_time;
        this.useCarForm.useCarName = row.car_operid;
        this.useCarForm.useCarMobile = row.mobile;
        this.useCarForm.remark = row.reason;
        this.useCarForm.approver = row.reviewerid;
      });
    },
    useCarApplication(status) {
      this.$confirm("是否确认该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/auditCarRecord.do", {
            id: this.useCarForm.id,
            status,
            opinion: this.useCarForm.opinion,
          })
            .then((res) => {
              this.loadUseCar(1);
              this.useFromVisible = false;
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .catch((err) => {
              this.useFromVisible = false;

              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
}
.header-radio-group {
  margin-bottom: 20px;
}
.use-car-form {
  margin-left: 30px;
  height: 500px;
  overflow-x: auto;
}
.header-query-frame {
  margin: 0 5px;
}
</style>
